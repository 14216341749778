@import "./mixins";
@import "./About.scss";
@import "./Editor.scss";
@import "./Playlists.scss";
@import "./Navbar.scss";

@font-face {
    font-family: 'Bauhaus';
    src: local('Bauhaus'), url(../shared/fonts/bauhaus-93/Bauhaus93Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Franklin Gothic Medium';
    src: local('Franklin Gothic Medium'), url(../shared/fonts/LibreFranklin/LibreFranklin-Medium.ttf) format('truetype');
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    //font-family: Bauhaus;
    color: #000000;
}

html,
body,
#root {
    height: 100%;
    height: -webkit-fill-available;
    max-height: -webkit-fill-available;;
}

body {
    background-color: $panel-head;
    //height: 100vh;
    width: 100vw;
    color: black;
}

.App {
    z-index: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    //padding-bottom: 10vh;
    align-items: stretch;

    @include sm-only {
        //height: calc(());
        //padding-bottom: 5em;
    }
}


.dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
    //height: 100%;
    //z-index: 0;
    height: auto;
    position: relative;
    //flex: 1;
    flex-grow: 1;
    //height: calc(100% - 65px);
    margin-bottom: 10vh;

    @include sm {
        flex-direction: row;
        width: 90%;
        margin: 0 auto 10vh;
        //margin-bottom: ;
        //padding: 1rem 0rem;

        //justify-content: space-between;
    }

    @include md {
        // align-items: flex-start;
        //padding: 1rem 3rem;
        // width: 100%;
    }

}

.main {
    @include sm {
        flex: 2;
        margin: 1rem 15px 0;
    }

    @include md {

        max-width: 50rem;
        height: 80vh;
    }

    //flex: 1 1;
    flex-grow: 1;
    position: relative;

    margin: 50px 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;

    >.panel .panel-header.Playlists {
        transform: translateY(-8px);
    }

    >button {
        position: absolute;
        top: 46px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: 4.55;
        height: 38px;
        font-size: 1.4rem;

        @include sm {
            &:hover {
                box-shadow: 5px 5px 3px rgba($color: #000000, $alpha: 1);
            }
        }
    }
}

.cross {
    position: relative;
    height: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

    .line {
        position: absolute;
        width: 100%;
        height: 16%; //.4rem;
        background-color: black;
    }

    :nth-child(1) {
        transform: rotate(45deg);
    }

    :nth-child(2) {
        transform: rotate(-45deg);
    }
}

.panel {
    @include sm {
        //font-size: 2rem;
        //grid-template-columns: 1fr 8fr 1fr;
    }

    background-color: $panel-head;
    width: 100%;
    height: 65px;
    display: grid;
    align-items: center;
    padding: 0 10px;
    font-size: 2rem;
    border-bottom: 2px solid black;
    grid-template-columns: 1fr 8fr 1fr;

    .close {
        //@include sm{
        justify-content: flex-end;
        align-content: flex-end;
        //}
        //justify-content: flex-start;
        //aspect-ratio: 1 / 1;

        display: flex;
        height: 50%;
        margin-right: 10px;
    }

    .panel-header {
        text-align: center;
        grid-column-start: 2;
        letter-spacing: 2px;
        input{
            letter-spacing: 2px;
        }
    }
}

.title {
    font-family: Bauhaus;
}

.window {
    border: 2px solid black;
    background-color: $panel-background;
}

.shadow {
    box-shadow: 0px 0px 15px 5px rgba($color: #000000, $alpha: 0.5);

    @include sm {
        box-shadow: 15px 15px 5px rgba($color: #000000, $alpha: 0.5);
    }
}

.red-btn{
    background-color: rgba($color: #FF6961, $alpha: 1.0);
}
.grn-btn{
    background-color: $btn-background;
}

.red-btn,
.grn-btn {
    border-radius: 15px;
    border: 2px solid black;
    box-shadow: 7px 7px 5px rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
    font-family: Bauhaus;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    /*@include sm-only{
        &:active, .clicked{
            transform: translate(2px, 2px);
            /*animation-name: button-push;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
        }
        
    }
    @keyframes button-push {
        0%{
            transform: translate(0px, 0px);
        }
        50%{
            transform: translate(2px, 2px);
        }
        100%{
            transform: translate(0px, 0px);
        }
    }*/

    @include sm {

        &:hover {
            box-shadow: 5px 5px 3px rgba($color: #000000, $alpha: 1);
            transform: translate(-1px, -1px);
            &:active{
                transform: translate(1px, 1px);
            }
        }

    }
}

.logout-btn{
    margin: auto;
    padding: 3.5px 7.5px;
    display: block;
    margin-right: 15px;
    //margin-bottom: 15px;

}

.pre {
    background: #000;
    color: white;
    font-family: monospace;
    padding: 1ch;
}

.pre.caret-underscore>span {
    animation: blink 1s step-end infinite;
    border-bottom: 2px solid black;
}

@keyframes blink {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: #fff;
    }
}

.playlist-item {
    height: 70px;
}