.welcome{
    margin-top: 30px;
    text-align: center;
    padding: 0 1em;
    span{
        display: inline-block;
        white-space: pre;
    }
}
.login-holder{
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.3em;
    margin-top: 30px;
    padding: 5px 10px;
    //font-weight: 100;
    img{
        min-height: 21px;
        height: 1.7rem;
        margin-left: 5px;
    }

}
.grn-btn.login{
    //display: flex;
    //align-items: center;
    //justify-content: center;
    width: fit-content;
    height: 1.3rem;
}
