@import "./mixins";

.about {
    @include sm {
        max-width: 14em;
        height: fit-content;
        max-height: 90vh;
        margin-right: 1em;
        position: static;
        //top: 0;
        min-width: 14em;
        flex: 1;
        max-width: 95%;
        margin-top: 2em;

        display: flex;
        flex-direction: column;
        transform: translateY(0%);
        box-shadow: 15px 15px 5px rgba($color: #000000, $alpha: 0.5) ;

    }
    @include md {
        max-width: 14em;
    }
    z-index: 1;

    position: absolute;
    width: 100vw;
    top: -1px;
    transform: translateY(-101%);
    transition: all 0.4s ease-in-out;
    
    @include sm-only {
        &.toggled{
            transform: translateY(0);
            border: 0px solid black;
            border-bottom: 2px solid black;
        }
        &.untoggled{
            box-shadow: none;
        }
    }

    .content{
        padding: 16px;
        margin: 16px;
        background-color: $panel-head;
        border: 2px solid black;
        font-size: 1.2rem;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        text-align: center;
    }
    .content:nth-child(3) {
        // font-size: 1rem;
        margin-top: auto;
    }
}