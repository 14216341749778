@import "./mixins";

.editor {
    @include sm{
        row-gap: 15px;
        top: 75px;
        left: 15px;
        min-height: 50vh;
        width: 90%;
        grid-template-columns: 12em 1fr 1fr;
        grid-template-rows: 65px auto;
        max-width: 35em;
        //padding-bottom: 15px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 65px auto auto;
    row-gap: 15px;
    //column-gap: 15px;
    top: 10px;
    height: fit-content;
    width: 106%;
    position: absolute;
    @include sm-only{
        max-width: 400px;
    } 
    >:first-child{
        grid-column: 1/4;
    }
    .control-panel{
        display: flex;
        flex-direction: column;
        margin:  0 15px;
        button{
            font-size: 1.5rem;
            margin-bottom: 15px;
            &:last-child.experimental{
                display: none;
                align-self: flex-start;
                padding: 0 15px;
                font-size: 1.2rem;
            }
        }
        >div{
            margin-top: auto;
            button{
                padding: 0 15px;
                font-size: 1.2rem;
            }
            display: flex;
            justify-content: space-between;
            @include lg{
                flex-direction: column-reverse;
                button{
                    margin-bottom: 15px;
                }
                .red-btn{
                    width: fit-content;
                }
                //max-height: ;
            }
        }
        @include lg{
            grid-row: 2;
            grid-column: 1/2;  
        }
        grid-row: 3;
        grid-column: 1/4;
    }
    .playlist-contents{
        background-color: $panel-head;
        border: 2px solid black;
        margin: 0 15px;
        width: auto;
        overflow-y: scroll;
        height: 296px;
        @include lg{
            grid-column: 2/4;
            margin-bottom: 15px;
            //height: 302px;
        }
        grid-column: 1/4;
        grid-row: 2;
        @include sm {
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .playlist-item{
            height: 34px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 7.5px;
            border-bottom: 2px solid black;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-size: 1.2rem;
            width: 100%;
            >div:nth-child(1){
                display: flex;
                height: 100%;
                width: calc(100% - 25px);
            }
            .spotify-logo{
                display: none;
                margin-right: 5px;
                min-height: 21px;
                min-width: 21px;
            }
            .type{
                //margin-right: .5rem;
                @include lg{
                    //width: 52px;
                }
                padding-right: 10px;
                width: fit-content;
                max-width: 3.75rem;
                //margin-right: 7.5px;;
            }
            .value{
                //display: block;
                //margin-left: 5px;
                padding-left: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80%;//220px;
                flex-shrink: 1;
                text-transform:capitalize;
            }
            &:nth-child(n + 9){
                &:last-child{
                    border-bottom: 0px solid black;
                }
            }
        }
    }
    @media only screen and (max-height: 670px) {
        //top: -10px;
        .playlist-contents{
            min-height: 280px;
        }
    }
}
.input{
    @include lg{
        width: 90%;
        max-width: 34rem;
        top: 50px;
        left: 15px;
        min-height: 11.5rem;
        transform: translateX(0%);
    }
    
    position: absolute;
    width: 96%;
    max-width: 34rem;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);

    //left: 15px;
    min-height: 11.5rem;
    .input-container{
        @include sm{
            flex-direction: row;
        }
        // width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        //height: 7rem;
        padding: 0 15px;
        .autoCompleteTextBox {
            @include lg{
                height: 3.8rem;
                width: 22rem;
                //margin: 0;
            }
            margin: .5em 1em;
            height: 3.8rem;
            width: 100%;
            //display: inline-block;
            display: flex;
            background-color: $panel-head;
            //justify-content: flex-start;
            //align-items: flex-start;

            *{
                //width: 100px;
                //flex: 1 1 0;
                background-color: transparent;
                //width: fit-content;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                font-size: 2rem;
                background-color: $panel-head;
            }
            .input-holder {
                /*flex: 1;
                flex-shrink: 1;*/
                position: relative;
                width: 100%;
                border: 2px solid black;


                input, .fake-input {
                    text-align: left;
                    //text-align: center;
                    width: 100%;
                    height: 100%;
                    padding: 0 .5em;
                }

                .real-input{
                    z-index: 10;
                    position: absolute;
                    //padding-right: 1em;
                    &:focus{
                        background-color: transparent;
                    }
                }
                .fake-input{
                    color: gray;
                    position: absolute;
                    display: flex;
                    z-index: 0;
                    top: 0;
                    left: 0;
                    align-items: center;
                    white-space: nowrap;
                    //padding: 0 1;
                    //text-align: center;
                    //overflow: hidden;
                    // text-overflow: clip;                    
                    //justify-content: center;
                }
            }
            
        }
        button{
            //height: 2.4rem;
            //width: 6rem;
            font-size: 1.3rem;
            margin-bottom: .5em;
            padding: 3px 7.5px;
            @include sm{
                margin: 0;
            }
        }
    }
}

input {
    outline: 0;
    border: 0px solid transparent;
    text-align: center;
    background-color: $panel-head;

    &:focus{
        outline: none !important;
    }
}

.title input{
    height: 100%;
    width: 80%;
    font-family: Bauhaus;
    font-size: 100%;
}
