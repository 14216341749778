@import "./mixins";

.playlist-container{
    @include sm {
        //flex: none;
        padding: 50px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    //@include sm-only{
        
    //}
    overflow-y: scroll;
    //overflow: hidden;
    //flex: 1 0 auto;
    min-height: 0px;
    //max-height: calc(100% - 65px - 50px);
    height: calc(100vh - 65px - 50px - 65px - 10vh);
    flex-grow: 1;
    flex-shrink: 0;
    padding: 40px 20px;
    column-gap: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(19.4rem, 100%), 1fr));
    grid-auto-rows: minmax(min-content, max-content);
    justify-content: center;
}
.playlist{
    display: flex;
    // flex-direction: row;
    align-items: center;
    background-color: $panel-head;
    //line-height: 1.5em;
    font-family: Bauhaus;
    height: 4rem;
    border: 2px solid black;
    margin-bottom: 15px;
    max-width: 20rem;
    justify-content: space-between;
    justify-self: center;
    padding: 0 15px;
    //flex: 1;
    width: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    h2{
        flex: 1;
        height: 100%;
        text-align: center;
        //padding: 1rem 0px;
        line-height: 60px;
        // align-items: center;
    }
    button {
        width: 4rem;
        height: 2rem;
        font-family: Bauhaus;
        font-size: 1rem;
        background-color: #FAF2DA;
        border: 2px solid black;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
        &:hover{
            box-shadow: 5px 5px 3px rgba($color: #000000, $alpha: 1) ;    
            transform: translate(-1px, -1px);
        }
    }
    .metaRow{
        height: fit-content;
        width: fit-content;
        max-height: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 5px;
        //flex: 1 .5;
        //justify-content: center;
        //align-items: center;
        h4{
            height: 50%;
            text-align:left;
            display: none;
        }
        .spotify-logo{
            background-color: transparent;
            height: 1.5rem;
            width: 1.5rem;
            min-height: 21px;
            aspect-ratio: 1/1;
            //width: calc()
            svg, img{
                
                height: 100%;
                width: 100%;
            }
        }
    }
}
