@import "./mixins";
.nav-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    align-items: center;
    background-color: $panel-background;
    padding: 0 10px;
    border-bottom: 2px solid black;
    z-index: 99;
    position: relative;

    >* {
        @include sm {
            flex: 1;
        }
    }

    h1 {
        font-family: Bauhaus;
        letter-spacing: 2px;
        font-size: 2.5rem;
        cursor: pointer;
        width: fit-content;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -webkit-tap-highlight-color: transparent;
    }

    .banner {
        font-size: 1rem;
        display: none;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        height: fit-content;
        justify-content: center;
        text-align: center;

        @include md {
            display: flex;
            font-size: 1.5rem
        }
    }

    .toggle {

        height: 85%;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;

        .open,
        .closed {
            @include sm {
                display: none;
            }
            cursor: pointer;
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            -webkit-tap-highlight-color: transparent;
            height: 90%;
            aspect-ratio: 1/1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .line {
                width: 100%;
                height: .4rem;
                background-color: black;
            }
        }

        .loading-holder {
            height: 100%;
            aspect-ratio: 1/1;
            position: relative;
            margin-right: 5px;
            sm-only{
                cursor: pointer;
                -webkit-tap-highlight-color: rgba(0,0,0,0);
                -webkit-tap-highlight-color: transparent;
            }

        }

        .profile-pic {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                aspect-ratio: 1/1;
                height: calc(100% - 4px);
                border-radius: 50%;
            }
        }

        .border {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            top: 0;
            border: $btn-background 2px solid;
            border-top-color: $panel-head;
            border-left-color: $panel-head;
            display: block;
            position: absolute;
            z-index: -1;
            will-change: transform;

            &.loading {
                animation-name: rotate;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-duration: .3s;
            }

            @keyframes rotate {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }

        //}
    }

}
